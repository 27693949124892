import { useEffect, useState, useLayoutEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import routerPc from './router/pc'
import routerMobile from './router/mobile'

const App = () => {
  const [isPc, setIsPc] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 750) {
      setIsPc(true)
    } else {
      setIsPc(false)
    }
    const handleResizeDebounced = function () {
      if (window.innerWidth > 750) {
        setIsPc(true)
      } else {
        setIsPc(false)
      }
    }

    // 添加事件监听器
    window.addEventListener('resize', handleResizeDebounced)
    return () => {
      window.removeEventListener('resize', handleResizeDebounced)
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        {(isPc ? routerPc : routerMobile).map((item, index) => (
          <Route {...item} key={index} />
        ))}
      </Routes>
    </BrowserRouter>
  )
}

export default App
