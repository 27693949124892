import { lazy } from 'react';

const Home = lazy(() => import('../mobile'))
const Privacy = lazy(() => import('../page/privacy'))
const Refund = lazy(() => import('../page/refund'))
const Term = lazy(() => import('../page/term'))

const router = [
  {
    path: '/',
    element: <Home />,
    title: '首页'
  },
  {
    path: '/privacy',
    element: <Privacy />,
    title: '隐私协议'
  },
  {
    path: '/refund',
    element: <Refund />,
    title: '退款协议'
  },
  {
    path: '/termsconditions',
    element: <Term />,
    title: '用户协议'
  }
]

export default router